// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
exports.push([module.id, "._3KaPBYdlb3XxtijSpYdlEt{padding-top:44px;display:flex;justify-content:center;align-items:center}.mFDOFecFwL-hKdEeZ1Enk{padding-top:22px}.mFDOFecFwL-hKdEeZ1Enk img{width:200px}\n", ""]);
// Exports
exports.locals = {
	"header": "_3KaPBYdlb3XxtijSpYdlEt",
	"shrinkLogo": "mFDOFecFwL-hKdEeZ1Enk"
};
module.exports = exports;

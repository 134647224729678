import React from "react";
import classnames from "classnames";
import { Link as RouterLink } from "react-router-dom";

import styles from "./Link.module.scss";

interface LinkProps {
  url: string;
  name: string;
  variant?: "normal" | "dark";
  styles?: Record<string, string>;
}

const Link: React.FC<LinkProps> = (props) => {
  const s = props.styles || styles;
  const variant = props.variant ?? "normal";

  return (
    <RouterLink className={classnames(styles.link, s[variant])} to={props.url}>
      {props.name}
    </RouterLink>
  );
};

export default Link;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
exports.push([module.id, ".ezsfqRzQmjrCmSooLH2yh{display:flex;align-items:center;height:24px;min-width:24px;padding:0 8px;gap:8px}._16chMQlKOA6G5YImG8Ok4Y{background-color:#d0dae9;font-style:normal;font-weight:600;font-size:14px;line-height:18px;color:#8292b1}.RR9PnCdZt5_ahzhhzeYi6{border:1px solid #ecedf5;border-radius:32px;background-color:#fff;box-shadow:0 2px 4px rgba(153,168,198,0.25);font-style:normal;font-weight:600;font-size:12px;line-height:16px;color:#333}\n", ""]);
// Exports
exports.locals = {
	"root": "ezsfqRzQmjrCmSooLH2yh",
	"static": "_16chMQlKOA6G5YImG8Ok4Y",
	"floating": "RR9PnCdZt5_ahzhhzeYi6"
};
module.exports = exports;

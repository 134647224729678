// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
exports.push([module.id, "._3caqP77JChKTFHuF9FU4se{color:#333;font-size:12px}._2vox3wAoT8F2xViZxweJVI{color:#8292b1}.rc-slider .rc-slider-handle:active{box-shadow:none}.primary.rc-slider-handle:active{box-shadow:none}\n", ""]);
// Exports
exports.locals = {
	"rail": "#ecedf5",
	"primary": "#333",
	"secondary": "#fcc067",
	"default": "#b8baca",
	"warning": "#fbab00",
	"info": "#64d0e8",
	"success": "#73c243",
	"danger": "#f44b55",
	"handleWidth": "18px",
	"handleHeight": "18px",
	"handleOffset": "3px",
	"dotWidth": "12px",
	"dotHeight": "12px",
	"dotOffset": "-3px",
	"mark": "_3caqP77JChKTFHuF9FU4se",
	"afterMark": "_2vox3wAoT8F2xViZxweJVI"
};
module.exports = exports;

import React from "react";
import { observer } from "mobx-react";

import ErrorPage from "./ErrorPage";

const NotFoundPage: React.FC = observer(() => {
  return <ErrorPage>404 - Page not found</ErrorPage>;
});

export default NotFoundPage;

import React, { useState } from "react";
import { default as RcSlider } from "rc-slider";
import classnames from "classnames";

import { Theme } from "../interfaces/Theme";

import "rc-slider/assets/index.css";
import styles from "./Slider.module.scss";

export interface SliderProps {
  className?: string;
  min?: number;
  max?: number;
  step?: number | null;
  defaultValue?: number;
  value?: number;
  customMarks?: { [k: number]: React.ReactNode };
  marks?: string[];
  onAfterChange?: (itemIndex: number) => void;
  onChange?: (value: number) => void;
  theme?: Theme;
  styles?: Record<string, string>;
  vertical?: boolean;
}

const Slider: React.FC<SliderProps> = (props) => {
  const s = props.styles || styles;

  const [active, setActive] = useState(props.defaultValue ? props.defaultValue - 1 : 0);
  const sliderTheme = props.theme ?? "default";

  const objectStyles = {
    railStyle: { background: s.rail },
    trackStyle: { background: s[sliderTheme] },
    dotStyle: {
      width: s.dotWidth,
      height: s.dotHeight,
      background: s.rail,
      borderColor: s.rail,
      top: s.dotOffset,
    },
    activeDotStyle: {
      width: s.dotWidth,
      height: s.dotHeight,
      background: s[sliderTheme],
      borderColor: s[sliderTheme],
      top: s.dotOffset,
    },
    handleStyle: {
      width: s.handleWidth,
      height: s.handleHeight,
      background: "white",
      borderColor: s[sliderTheme],
      top: s.handleOffset,
    },
  };

  function handleChange(value: number): void {
    setActive(value - 1);

    if (props.onChange) {
      props.onChange(value);
    }
  }

  function generateMarks(marks?: string[]): React.ReactNode {
    if (!marks) {
      return;
    }

    return marks.reduce<{ [k: number]: React.ReactNode }>((acc, item, index) => {
      acc[index + 1] = (
        <label
          className={classnames(s.mark, {
            [s[sliderTheme]]: index === active,
            [s.afterMark]: index > active,
          })}
          style={{ color: index === active && sliderTheme !== "default" ? s[sliderTheme] : "unset" }}
        >
          {item}
        </label>
      );
      return acc;
    }, {});
  }

  const marks = props.customMarks ?? generateMarks(props.marks);

  return (
    <RcSlider
      className={classnames(props.className, s[sliderTheme])}
      {...props}
      {...objectStyles}
      marks={marks}
      onChange={(value: number) => handleChange(value)}
    />
  );
};

export default Slider;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactAnalytics from "react-ga";

export default function usePageTracking(id: string): void {
  const location = useLocation();

  useEffect(() => {
    ReactAnalytics.initialize(id, {
      // Uncomment this `debug` flag when you want to show ga logs in browser console
      // debug: true
    });
  }, [id]);

  useEffect(() => {
    ReactAnalytics.pageview(`${location.pathname}${location.search}`);
  }, [location]);
}

import composeRefs from "@seznam/compose-react-refs";
import classNames from "classnames";
import * as React from "react";
import { useDrag, useDrop } from "react-dnd";
import { Flipped } from "react-flip-toolkit";

import { Button, Label } from "../";
import useHoverAudio from "../../lesson/hooks/useHoverAudio";
import useHoverAudioFormula from "../../lesson/hooks/useHoverAudioFormula";
import { DRAG_TYPE } from "../DropBag/DropBag";
import { Theme } from "../interfaces/Theme";

import defaultStyles from "./DragImage.module.scss";

export interface DragImageValue {
  id: string;
  imageUrl: string;
}

export interface DragImageItem extends DragImageValue {
  type: typeof DRAG_TYPE;
}

interface DragImageProps {
  /**
   * Item currently dropped in the box.
   * Undefined means empty box.
   */
  value?: DragImageValue;
  /**
   * Called when the user drops another item in this box.
   * Set to undefined to disable dropping.
   * @param id The {@link DragValue.id ID} of dropped value
   */
  onDrop?: (id: string) => void;
  /**
   * Called when the user clears the box.
   * Set to undefined to disable showing the clear button.
   */
  onClear?: () => void;
  /**
   * Content displayed when the box is empty.
   * Use empty string or false to draw an empty box.
   * Leave undefined to not draw anything at all.
   */
  placeholder?: React.ReactNode;
  /** Audio file which should play on hover. */
  hoverAudioUrl?: string;
  /** Audio wave formula which should play on hover. */
  hoverAudioFormula?: string;
  theme?: Theme;
  className?: string;
  styles?: Record<string, string>;
}

const DragImage: React.FC<DragImageProps> = (props) => {
  const {
    value,
    onDrop,
    onClear,
    placeholder,
    hoverAudioUrl,
    hoverAudioFormula,
    theme = "default",
    styles = defaultStyles,
  } = props;

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: DRAG_TYPE, ...value } as DragImageItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: DRAG_TYPE,
    canDrop: () => onDrop != null,
    drop: (item: DragImageItem) => {
      onDrop?.(item.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const hoverAudioRef = useHoverAudio(hoverAudioUrl);
  const hoverAudioFormulaRef = useHoverAudioFormula(hoverAudioFormula);

  const renderValue = (value: DragImageValue): React.ReactNode => (
    <>
      <Flipped flipId={value.id} shouldFlip={(prevData, nextData) => nextData?.lastDroppedId !== value.id}>
        <div
          className={styles.box}
          style={{
            ...(isDragging && { opacity: 0 }),
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={composeRefs<any>(dragRef, hoverAudioRef, hoverAudioFormulaRef)}
        >
          <img src={value.imageUrl} alt="Draggable image" />
          {onClear != null && (
            <Button
              className={styles.cornerLabel}
              color={"alert"}
              shape={"round"}
              size={"small"}
              icon="fas fa-times"
              onClick={onClear}
            />
          )}
        </div>
      </Flipped>

      {placeholder && (
        <Label className={styles.bottomLabel} variant="floating">
          {placeholder}
        </Label>
      )}
    </>
  );

  return (
    <div
      className={classNames(styles.slot, styles[theme], props.className, {
        [styles.empty]: placeholder != null,
        [styles.disabled]: placeholder != null && onDrop == null,
        [styles.active]: isOver && canDrop,
      })}
      ref={dropRef}
    >
      {value != null && renderValue(value)}
      {placeholder}
    </div>
  );
};

export default DragImage;

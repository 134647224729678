// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
exports.push([module.id, "._45Q5Du_9BzfB2AzCSERfM{position:relative;font-style:normal;font-weight:700;font-size:14px;margin:-2px;padding:12px 18px;border-color:#fcc067;background:#fff;color:#333;text-decoration:none;text-align:center;filter:drop-shadow(0px 3px 16px rgba(0,0,0,0.15));cursor:grab;transition:box-shadow 200ms;border-radius:39px;border-width:0}._45Q5Du_9BzfB2AzCSERfM:hover{color:#fff;background:#333333}._45Q5Du_9BzfB2AzCSERfM::after{position:absolute;display:block;content:\"\";bottom:0;left:50%;margin-bottom:4px;margin-left:-12px;width:24px;height:2px}._45Q5Du_9BzfB2AzCSERfM>.SfJoQML_OQU3RBI5lGjI0{position:absolute;top:50%;right:0;margin-top:-15px;margin-right:-12px}._1wnltj8TbtNh2CH4ApfGX-{position:relative;display:flex;margin:6px;flex-direction:column;justify-content:space-around;border:1px dashed #008ac6;transition:box-shadow 200ms;box-shadow:inset 0px 2px 8px #008ac6;border-radius:32px;min-width:150px}._1Oosutq9o80uQVEPG9ESfM{border:none;background:none;box-shadow:none}\n", ""]);
// Exports
exports.locals = {
	"label": "_45Q5Du_9BzfB2AzCSERfM",
	"sideLabel": "SfJoQML_OQU3RBI5lGjI0",
	"slot": "_1wnltj8TbtNh2CH4ApfGX-",
	"slotInvisible": "_1Oosutq9o80uQVEPG9ESfM"
};
module.exports = exports;

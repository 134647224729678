// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
exports.push([module.id, ".c-YtBQPtc7jS6v1WMUzOp{display:flex;justify-content:space-between;align-items:center;margin:36px 80px}._1B9WmkaxM-l73hyqM-BmY0{display:flex;align-items:center}._1B9WmkaxM-l73hyqM-BmY0 a{margin:0 20px}._1B9WmkaxM-l73hyqM-BmY0:first-child{margin-left:none}._1B9WmkaxM-l73hyqM-BmY0:last-child{margin-right:none}._353uEbMknNi8llJQtwMTQF a{margin:0 8px}\n", ""]);
// Exports
exports.locals = {
	"footer": "c-YtBQPtc7jS6v1WMUzOp",
	"partners": "_1B9WmkaxM-l73hyqM-BmY0",
	"social": "_353uEbMknNi8llJQtwMTQF"
};
module.exports = exports;

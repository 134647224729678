import React from "react";

import { Button, Modal } from "@lms/ui";
import { Theme } from "../../interfaces/Theme";

export interface PromptProps {
  title: string;
  message: string;
  visible: boolean;
  icon?: string;
  onConfirm: () => void;
  onDismiss?: () => void;
  theme?: Theme;
  confirmButtonText?: string;
  dismissButtonText?: string;
}

const Prompt: React.FC<PromptProps> = (props: PromptProps) => {
  const { confirmButtonText = "OK", dismissButtonText = "Cancel" } = props;

  function onAction(action?: () => void): void {
    if (action) {
      action();
    }
  }

  const buttons = [
    <Button key="1" color={"default"} onClick={() => onAction(props.onDismiss)}>
      {dismissButtonText}
    </Button>,
    <Button key="2" color={props.theme ?? "default"} onClick={() => onAction(props.onConfirm)}>
      {confirmButtonText}
    </Button>,
  ];

  return <Modal {...props} buttons={buttons} />;
};

export default Prompt;

import React from "react";
import { observer } from "mobx-react";

import BasicLayout from "../components/Layout/BasicLayout";

const ErrorPage: React.FC = observer((props) => {
  return <BasicLayout>{props.children}</BasicLayout>;
});

export default ErrorPage;

import React from "react";
import Tippy from "@tippyjs/react";

import arrow from "./popoverArrow";

import "tippy.js/dist/svg-arrow.css";
import styles from "./Popover.module.scss";

interface PopoverProps {
  mainTxt?: string;
  subTxt?: string;
  children: React.ReactElement;
  customContent?: React.ReactNode;
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "top-start"
    | "left-start"
    | "right-start"
    | "bottom-start"
    | "top-end"
    | "left-end"
    | "right-end"
    | "bottom-end";
  trigger?: "click" | "mouseenter focus";
  duration?: number;
  styles?: Record<string, string>;
}

const Popover: React.FC<PopoverProps> = (props: PopoverProps) => {
  const s = props.styles || styles;

  function renderPopover(): React.ReactNode {
    if (props.customContent == null) {
      return (
        <div className={s.popover}>
          <span className={s.subtxt}>{props.subTxt}</span>
          <span className={s.maintxt}>{props.mainTxt}</span>
        </div>
      );
    }

    return <div className={s.popover}>{props.customContent}</div>;
  }

  return (
    <Tippy
      content={renderPopover()}
      className={s.tippy}
      placement={props.placement ?? "top"}
      arrow={arrow}
      allowHTML={true}
      interactive={!!props.customContent}
      offset={[0, 15]}
      trigger={props.trigger ?? "mouseenter focus"}
      duration={props.duration ?? 0}
    >
      {props.children}
    </Tippy>
  );
};

export default Popover;

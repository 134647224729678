import { ListboxArrow, ListboxButton, ListboxInput, ListboxList, ListboxOption, ListboxPopover } from "@reach/listbox";
import { Position } from "@reach/popover";
import classNames from "classnames";
import React from "react";

import { Theme } from "../interfaces/Theme";

import "@reach/listbox/styles.css";
import defaultStyles from "./Dropdown.module.scss";

/** Custom popover position which covers the button */
const positionCover: Position = (target, popover) => {
  if (!target || !popover) return {};

  return {
    width: target.width,
    left: target.left,
    top: target.top + window.pageYOffset,
  };
};

interface DropdownProps extends Omit<React.ComponentProps<typeof ListboxInput>, "children"> {
  /** The list of options to select */
  options: Array<React.ComponentProps<typeof ListboxOption>>;
  /**
   * Props passed to the button element.
   * That is the part which shows the current value and opens the popover on click.
   */
  buttonProps?: React.ComponentProps<typeof ListboxButton>;
  /**
   * Props passed to the popover element.
   * That is the part which shows available options and is initially hidden.
   */
  popoverProps?: React.ComponentProps<typeof ListboxButton>;
  /**
   * The text to show in place of the current value when no option is selected.
   * It's displayed when current `value` prop doesn't match any of the option's values.
   */
  placeholder?: string;
  theme?: Theme;
  styles?: Record<string, string>;
}

/** Dropdown field similar to HTML `<select>` */
const Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    placeholder,
    options,
    buttonProps,
    popoverProps,
    theme = "default",
    styles = defaultStyles,
    ...inputProps
  } = props;

  return (
    <ListboxInput {...inputProps} className={classNames(inputProps.className)}>
      {(listboxState) => {
        const arrow = (
          <i className={classNames(styles.arrow, "fas", `fa-chevron-${listboxState.expanded ? "up" : "down"}`)} />
        );
        const currentLabel = listboxState.valueLabel ?? placeholder ?? <>&nbsp;</>;

        return (
          <>
            {/* the always-visible part of the dropdown */}
            <ListboxButton
              arrow={arrow}
              {...buttonProps}
              className={classNames(styles.input, buttonProps?.className, styles[theme])}
            >
              <span className={styles.inputText}>{currentLabel}</span>
            </ListboxButton>

            {/* the part which shows after a click */}
            <ListboxPopover
              position={positionCover}
              {...popoverProps}
              className={classNames(styles.popover, popoverProps?.className, styles[theme])}
            >
              <ListboxList className={styles.list}>
                {/* first line shows the current value and isn't clickable */}
                <li className={styles.option}>
                  {currentLabel}
                  <ListboxArrow>{arrow}</ListboxArrow>
                </li>

                {/* the rest of popover content is customizable */}
                {options.map((optProps) => (
                  <ListboxOption
                    key={optProps.value}
                    {...optProps}
                    className={classNames(styles.option, optProps.className)}
                  />
                ))}
              </ListboxList>
            </ListboxPopover>
          </>
        );
      }}
    </ListboxInput>
  );
};
export default Dropdown;

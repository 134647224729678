import { compile } from "mathjs";
import { Ref, useCallback, useMemo } from "react";
import useAudioContext from "./useAudioContext";
import { AudioBufferSourceNode, AudioContext } from "standardized-audio-context";
import useRefEffect from "./useRefEffect";

/** Buffer length in seconds. */
const length = 1;

export default function useHoverAudioFormula(formula: string | undefined): Ref<Element> {
  const audioContext = useAudioContext();

  const audioBuffer = useMemo(() => {
    if (formula == null) return undefined;
    const size = audioContext.sampleRate * length;
    const buffer = audioContext.createBuffer(1, size, audioContext.sampleRate);
    const data = buffer.getChannelData(0);
    const func = compile(formula);
    for (let index = 0; index < size; index += 1) {
      const t = index / audioContext.sampleRate;
      data[index] = func.evaluate({ t });
    }
    return buffer;
  }, [audioContext, formula]);

  return useRefEffect(
    useCallback(
      (element: Element) => {
        let audioSource: AudioBufferSourceNode<AudioContext>;

        function stop(): void {
          audioSource?.stop();
        }

        function start(): void {
          stop();
          if (audioBuffer == null) return;
          audioSource = audioContext.createBufferSource();
          audioSource.connect(audioContext.destination);
          audioSource.buffer = audioBuffer;
          audioSource.loop = true;
          audioSource.start();
        }

        element.addEventListener("mouseenter", start);
        element.addEventListener("mouseleave", stop);

        return () => {
          element.removeEventListener("mouseenter", start);
          element.removeEventListener("mouseleave", stop);
          stop();
        };
      },
      [audioBuffer, audioContext]
    )
  );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
exports.push([module.id, "._1AMAN41EOWJ-wxJteXQIij{position:fixed;top:0;left:0;width:100vw;height:100vh;display:grid;place-items:center;backdrop-filter:blur(2px);-webkit-backdrop-filter:blur(2px);z-index:100000}.ie-8BgwD-2ac3DjEpe_Oq{position:relative;padding:20px;background:#fff;min-width:330px;max-width:400px;border:1px solid #d0dae9;display:flex;flex-direction:column;align-items:center;box-shadow:0 2px 4px rgba(153,168,198,0.25)}.ie-8BgwD-2ac3DjEpe_Oq ._2WquoZbdW9WxP32LBpCvzA{font-size:3em}.ie-8BgwD-2ac3DjEpe_Oq ._2O1Xa248elcyaDJssXUius{position:absolute;right:16px;top:16px}.ie-8BgwD-2ac3DjEpe_Oq ._2O1Xa248elcyaDJssXUius:hover{cursor:pointer}.ie-8BgwD-2ac3DjEpe_Oq .VF5tKQDd700StgIJAcYhK{display:flex;flex-direction:column;justify-content:center;align-items:center}.ie-8BgwD-2ac3DjEpe_Oq h1{margin-top:0;font-size:1em;color:#333}.ie-8BgwD-2ac3DjEpe_Oq p{color:#333;font-size:0.75em;text-align:center}.ie-8BgwD-2ac3DjEpe_Oq._3zH1zzb8AonCStIxq-oga- h1,.ie-8BgwD-2ac3DjEpe_Oq._3zH1zzb8AonCStIxq-oga- ._2WquoZbdW9WxP32LBpCvzA{color:#333}.ie-8BgwD-2ac3DjEpe_Oq._31SZMLeyt8xQUVkpLJfT8I h1,.ie-8BgwD-2ac3DjEpe_Oq._31SZMLeyt8xQUVkpLJfT8I ._2WquoZbdW9WxP32LBpCvzA{color:#fcc067}.ie-8BgwD-2ac3DjEpe_Oq._3pMQCPdPEgBw6YSq99Dt5K h1,.ie-8BgwD-2ac3DjEpe_Oq._3pMQCPdPEgBw6YSq99Dt5K ._2WquoZbdW9WxP32LBpCvzA{color:#b8baca}.ie-8BgwD-2ac3DjEpe_Oq._30sjF53LVuMyv8hlf7fOjs h1,.ie-8BgwD-2ac3DjEpe_Oq._30sjF53LVuMyv8hlf7fOjs ._2WquoZbdW9WxP32LBpCvzA{color:#fbab00}.ie-8BgwD-2ac3DjEpe_Oq._3EwHmGDw4fOkoBS2NG_t1P h1,.ie-8BgwD-2ac3DjEpe_Oq._3EwHmGDw4fOkoBS2NG_t1P ._2WquoZbdW9WxP32LBpCvzA{color:#64d0e8}.ie-8BgwD-2ac3DjEpe_Oq._1QbESMYXOHpclSiJpXVCVX h1,.ie-8BgwD-2ac3DjEpe_Oq._1QbESMYXOHpclSiJpXVCVX ._2WquoZbdW9WxP32LBpCvzA{color:#73c243}.ie-8BgwD-2ac3DjEpe_Oq._3fFV6CdDui2LavRNSPuhMz h1,.ie-8BgwD-2ac3DjEpe_Oq._3fFV6CdDui2LavRNSPuhMz ._2WquoZbdW9WxP32LBpCvzA{color:#f44b55}\n", ""]);
// Exports
exports.locals = {
	"modalContainer": "_1AMAN41EOWJ-wxJteXQIij",
	"modal": "ie-8BgwD-2ac3DjEpe_Oq",
	"icon": "_2WquoZbdW9WxP32LBpCvzA",
	"closeIcon": "_2O1Xa248elcyaDJssXUius",
	"content": "VF5tKQDd700StgIJAcYhK",
	"primary": "_3zH1zzb8AonCStIxq-oga-",
	"secondary": "_31SZMLeyt8xQUVkpLJfT8I",
	"default": "_3pMQCPdPEgBw6YSq99Dt5K",
	"warning": "_30sjF53LVuMyv8hlf7fOjs",
	"info": "_3EwHmGDw4fOkoBS2NG_t1P",
	"success": "_1QbESMYXOHpclSiJpXVCVX",
	"alert": "_3fFV6CdDui2LavRNSPuhMz"
};
module.exports = exports;

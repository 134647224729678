// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
exports.push([module.id, ".ro4lt9at02pyeRpY7_8in h1,.ro4lt9at02pyeRpY7_8in h2,.ro4lt9at02pyeRpY7_8in h3,.ro4lt9at02pyeRpY7_8in h4,.ro4lt9at02pyeRpY7_8in h5,.ro4lt9at02pyeRpY7_8in h6{font-family:\"roc-grotesk\",sans-serif;font-style:normal;color:#333}.ro4lt9at02pyeRpY7_8in h1{font-weight:bold;font-size:24px;line-height:27px;color:#64d0e8}.ro4lt9at02pyeRpY7_8in h2{font-size:14px;font-weight:bold;line-height:22px}.ro4lt9at02pyeRpY7_8in h3{font-size:12px;line-height:17px;letter-spacing:0.16em;color:#000000}.ro4lt9at02pyeRpY7_8in ul{padding:0 0 0 1em}.ro4lt9at02pyeRpY7_8in ul li{margin-top:1em}.ro4lt9at02pyeRpY7_8in .__formatter-simple__,.ro4lt9at02pyeRpY7_8in p,.ro4lt9at02pyeRpY7_8in li{font-family:\"Open Sans\",sans-serif;font-style:normal;font-weight:normal;font-size:14px;line-height:150%;color:#333}.ro4lt9at02pyeRpY7_8in a{font-style:italic;font-weight:700;text-decoration:none}.ro4lt9at02pyeRpY7_8in a:link{color:#333}.ro4lt9at02pyeRpY7_8in a:hover{text-decoration:underline}\n", ""]);
// Exports
exports.locals = {
	"slide": "ro4lt9at02pyeRpY7_8in"
};
module.exports = exports;

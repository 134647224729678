import classNames from "classnames";
import React, { forwardRef, Ref } from "react";
import { Theme } from "../interfaces/Theme";
import defaultStyles from "./TextField.module.scss";

type TextFieldProps = React.InputHTMLAttributes<HTMLInputElement> &
  React.TextareaHTMLAttributes<HTMLInputElement> & {
    component?: React.ElementType;
    /** Text shown below the text field. */
    helperText?: React.ReactNode;
    theme?: Theme;
    styles?: Record<string, string>;
  };

const TextField = forwardRef(function TextField(
  props: TextFieldProps,
  ref: Ref<HTMLInputElement | HTMLTextAreaElement>
) {
  const { component: Component = "input", helperText, color = "default", styles = defaultStyles, ...rootProps } = props;
  const { className, style, ...inputProps } = rootProps;

  return (
    <div className={classNames(defaultStyles.root, styles[color], className)} style={style}>
      <Component {...inputProps} className={styles.input} ref={ref} />
      {helperText != null && <p className={styles.helperText}>{helperText}</p>}
    </div>
  );
});

export default TextField;

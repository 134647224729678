// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
exports.push([module.id, "._1qXUQ14JO3bxtHnsXRLncP{max-width:1160px;min-height:100vh;margin:0 auto;display:flex;flex-direction:column}._39fLvSIc-LZVg2g44JzZ-Y{margin:33px;min-height:800px;display:flex}\n", ""]);
// Exports
exports.locals = {
	"root": "_1qXUQ14JO3bxtHnsXRLncP",
	"main": "_39fLvSIc-LZVg2g44JzZ-Y"
};
module.exports = exports;

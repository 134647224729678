import React, { useRef } from "react";
import { useClickAway } from "react-use";
import classnames from "classnames";

import { Icon } from "../";
import { Theme } from "../interfaces/Theme";

import styles from "./Modal.module.scss";

export interface ModalProps {
  title?: string;
  message?: string;
  buttons: React.ReactElement[];
  visible: boolean;
  children?: React.ReactElement;
  theme?: Theme;
  icon?: string;
  persistent?: boolean;
  onClose?: () => void;
  onDismiss?: () => void;
  styles?: Record<string, string>;
}

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const s = props.styles || styles;
  const { theme = "default" } = props;

  const modalRef = useRef<HTMLDivElement | null>(null);
  const persistent = props.persistent ?? false;

  function onCloseModal(): void {
    if (props.onClose) {
      props.onClose();
    }
  }

  function onDismissModal(): void {
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  useClickAway(modalRef, () => {
    if (!persistent) {
      onCloseModal();
      onDismissModal();
    }
  });

  function renderContent(): React.ReactNode {
    if (props.children) {
      return props.children;
    }

    return (
      <>
        <h1>{props.title}</h1>
        {props.icon && <Icon className={classnames(s.icon, props.icon)} />}
        {props.message && <p>{props.message}</p>}
      </>
    );
  }

  function renderModal(): React.ReactElement {
    return (
      <div className={s.modalContainer}>
        <div ref={modalRef} className={classnames(s.modal, s[theme])}>
          <Icon className={classnames(s.closeIcon, "cis-x")} onClick={() => onCloseModal()} />

          <div className={s.content}>{renderContent()}</div>

          <div>{props.buttons.map((button) => button)}</div>
        </div>
      </div>
    );
  }

  return <>{props.visible && renderModal()}</>;
};

export default Modal;

import React from "react";
import CoreTypography from "../../../../ui/Typography/Typography";

import styles from "./Typography.module.scss";

const Button: React.FC<React.ComponentProps<typeof CoreTypography>> = (props) => {
  return <CoreTypography {...props} styles={styles} mode={"slide"} />;
};

export default Button;

import React from "react";
import CoreDragLabel from "../../../../ui/DragLabel/DragLabel";

import styles from "./DragLabel.module.scss";

const DragLabel: React.FC<React.ComponentProps<typeof CoreDragLabel>> = (props) => {
  return <CoreDragLabel {...props} styles={styles} />;
};

export default DragLabel;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
exports.push([module.id, "._3KbqjPxHj3nM0Vqr6M_KjD{display:flex;flex-direction:column;align-items:stretch;min-height:64px;width:100%;transition:box-shadow 200ms;border-radius:32px}._1GaZ8cHFAXwKdAncPna1N1{box-shadow:inset 0px 2px 20px #008ac6}._1HNZ7i41E4RfVCQVqo2b98{display:none}._1GaZ8cHFAXwKdAncPna1N1 ._1HNZ7i41E4RfVCQVqo2b98{background:#ecedf5;color:#333}.W4otfIA0c7lwZsSVdYBOl{flex:1;display:flex;align-items:center;justify-content:space-evenly;flex-wrap:wrap;align-content:space-evenly;color:#008ac6;font-style:bold;font-weight:700;font-size:14px;border:2px dashed #008ac6;box-shadow:inset 0px 2px 8px #008ac6;border-radius:32px}.W4otfIA0c7lwZsSVdYBOl>*{margin:8px}\n", ""]);
// Exports
exports.locals = {
	"root": "_3KbqjPxHj3nM0Vqr6M_KjD",
	"active": "_1GaZ8cHFAXwKdAncPna1N1",
	"header": "_1HNZ7i41E4RfVCQVqo2b98",
	"content": "W4otfIA0c7lwZsSVdYBOl"
};
module.exports = exports;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
exports.push([module.id, ".y8KNPFu3btxul8iUY22e5{margin:100px auto 0;width:70px;text-align:center}.y8KNPFu3btxul8iUY22e5>*{width:18px;height:18px;background-color:#333;border-radius:100%;display:inline-block;animation:_3Z8SdjBLVVmrEQ4Mil3iA_ 1.4s infinite ease-in-out both}.y8KNPFu3btxul8iUY22e5>:nth-child(1){animation-delay:-0.32s}.y8KNPFu3btxul8iUY22e5>:nth-child(2){animation-delay:-0.16s}@keyframes _3Z8SdjBLVVmrEQ4Mil3iA_{0%,80%,100%{transform:scale(0)}40%{transform:scale(1)}}\n", ""]);
// Exports
exports.locals = {
	"spinner": "y8KNPFu3btxul8iUY22e5",
	"bounce": "_3Z8SdjBLVVmrEQ4Mil3iA_"
};
module.exports = exports;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
exports.push([module.id, "._17yZSMxa8VU4pLUhUB4bFi{display:flex;flex-direction:column;align-items:stretch;width:100%;height:100%;border:1px dashed #ecedf5;background:#fff;box-shadow:inset 0 0 0 8px transparent,inset 0px 2px 4px rgba(153,168,198,0.25);transition:box-shadow 200ms}.BqzODSIWtuwkVodKJMTRJ{box-shadow:inset 0 0 0 8px #ecedf5,inset 0px 2px 4px rgba(153,168,198,0.25)}.wTdWWHg2LGU6qnuOrk-Ot{display:flex;align-items:center;justify-content:space-evenly;margin:0;min-height:32px;background:rgba(236,237,245,0.5);font-style:normal;font-weight:600;font-size:14px;line-height:18px;color:#8292b1}.BqzODSIWtuwkVodKJMTRJ .wTdWWHg2LGU6qnuOrk-Ot{background:#ecedf5;color:#333}._13q0gG1--IopfTH28tyEJK{flex:1;display:flex;align-items:center;justify-content:space-evenly;flex-wrap:wrap;align-content:space-evenly;font-style:normal;font-weight:600;font-size:12px;line-height:16px;color:#fcc067;background-repeat:no-repeat;background-position:center;background-size:cover}._13q0gG1--IopfTH28tyEJK>*{margin:8px}\n", ""]);
// Exports
exports.locals = {
	"root": "_17yZSMxa8VU4pLUhUB4bFi",
	"active": "BqzODSIWtuwkVodKJMTRJ",
	"header": "wTdWWHg2LGU6qnuOrk-Ot",
	"content": "_13q0gG1--IopfTH28tyEJK"
};
module.exports = exports;

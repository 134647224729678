import { Ref, useCallback, useRef } from "react";

/**
 * Runs an effect when the element ref changes.
 *
 * @param effect - The effect to run when the ref changes.
 * Receives the new element and can return a cleanup function.
 * You can use it to add and remove event listeners to the element.
 * Wrap your effect in `useCallback` if it's expensive to run.
 *
 * @returns A ref that you can assign to some JSX element.
 *
 * @see https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
 */
export default function useRefEffect<T extends Element>(effect: (element: T) => (() => void) | undefined): Ref<T> {
  const cleanupRef = useRef<() => void>();

  return useCallback(
    (element: T | null): void => {
      cleanupRef.current?.();
      cleanupRef.current = undefined;
      if (element != null) cleanupRef.current = effect(element);
    },
    [effect]
  );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js!normalize.css/normalize.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js!@fortawesome/fontawesome-free/css/all.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js!@coreui/icons-pro/css/all.min.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
exports.push([module.id, "@import url(https://use.typekit.net/ncv8ayx.css);"]);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
exports.push([module.id, "@import url(https://cdn.materialdesignicons.com/5.3.45/css/materialdesignicons.min.css);"]);
// Module
exports.push([module.id, "*,*:before,*:after{box-sizing:border-box}body{font-family:\"Open Sans\",sans-serif}body{background:#faf6ee}\n", ""]);
// Exports
module.exports = exports;

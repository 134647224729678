import * as React from "react";
import classNames from "classnames";
import styles from "./Label.module.scss";

interface LabelProps extends React.HTMLAttributes<HTMLElement> {
  component?: React.ElementType;
  variant?: "static" | "floating";
  styles?: Record<string, string>;
}

const Label: React.FC<LabelProps> = (props) => {
  const s = props.styles || styles;
  const { component: Component = "div", children, variant = "static", ...rootProps } = props;

  return (
    <Component {...rootProps} className={classNames(props.className, s.root, styles[variant])}>
      {children}
    </Component>
  );
};

export default Label;

import React from "react";

import styles from "./Typography.module.scss";

interface TypographyProps {
  mode?: "slide" | "theory";
  styles?: Record<string, string>;
}

const Typography: React.FC<TypographyProps> = (props) => {
  const s = props.styles || styles;
  const mode = props.mode ?? "slide";

  return <div className={s[mode]}>{props.children}</div>;
};

export default Typography;

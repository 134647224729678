import React from "react";
import Tippy from "@tippyjs/react";
import arrow from "./tooltipArrow";

import "tippy.js/dist/svg-arrow.css";
import styles from "./Tooltip.module.scss";

interface TooltipProps {
  label: string;
  children: React.ReactElement;
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "top-start"
    | "left-start"
    | "right-start"
    | "bottom-start"
    | "top-end"
    | "left-end"
    | "right-end"
    | "bottom-end";
  theme?: "light" | "dark";
  styles?: Record<string, string>;
}

const Tooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
  const s = props.styles || styles;

  const label = <div className={s.tooltip}>{props.label}</div>;
  return (
    <Tippy
      content={label}
      className={s.tippy}
      placement={props.placement ?? "top"}
      arrow={arrow}
      theme={props.theme}
      duration={0}
      delay={[350, 0]}
    >
      {props.children}
    </Tippy>
  );
};

export default Tooltip;

import React from "react";
import { observer } from "mobx-react";

import styles from "./BasicLayout.module.scss";
import Header from "./Header";
import Footer from "./Footer";

interface BasicLayoutProps {
  hideFooter?: boolean;
  shrinkLogo?: boolean;
}

const BasicLayout: React.FC<BasicLayoutProps> = observer((props) => {
  return (
    <div className={styles.root}>
      <Header shrinkLogo={props.shrinkLogo} />

      <main className={styles.main}>{props.children}</main>

      {!props.hideFooter && <Footer />}
    </div>
  );
});

export default BasicLayout;

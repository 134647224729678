import classNames from "classnames";
import * as React from "react";
import { useDrop } from "react-dnd";
import { DragImageItem } from "../DragImage/DragImage";
import styles from "./DropBag.module.scss";

export const DRAG_TYPE = Symbol("DRAG_TYPE");

interface DropBagProps {
  className?: string;
  /**
   * Box header content
   */
  header: React.ReactNode;
  /**
   * Called when the user drops an item in this box.
   * Set to undefined to disable dropping.
   * @param id The {@link DragValue.id ID} of dropped value
   */
  onDrop?: (id: string) => void;
  /**
   * Content displayed when the bag is empty.
   */
  placeholder?: React.ReactNode;
  backgroundImageUrl?: string;
  styles?: Record<string, string>;
}

const DropBag: React.FC<DropBagProps> = (props) => {
  const s = props.styles || styles;
  const { header, children, onDrop, placeholder, ...rootProps } = props;

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: DRAG_TYPE,
    canDrop: () => onDrop != null,
    drop: (item: DragImageItem) => {
      onDrop?.(item.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const style = props.backgroundImageUrl ? { backgroundImage: `url("${props.backgroundImageUrl}")` } : undefined;

  return (
    <div
      ref={dropRef}
      {...rootProps}
      className={classNames(props.className, s.root, { [s.active]: isOver && canDrop })}
    >
      <h4 className={classNames(s.header)}>{header}</h4>
      <div className={classNames(s.content)} style={style}>
        {React.Children.count(children) > 0 ? children : placeholder}
      </div>
    </div>
  );
};

export default DropBag;

import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import classnames from "classnames";
import appConfig from "appConfig";

import styles from "./Header.module.scss";

import logo from "../../assets/images/logo.svg";

interface HeaderProps {
  shrinkLogo?: boolean;
}

const Header: React.FC<HeaderProps> = observer((props) => {
  return (
    <header className={classnames(styles.header, { [styles.shrinkLogo]: props.shrinkLogo })}>
      <Link to="/">
        <img src={logo} alt={appConfig.HALO_TITLE} />
      </Link>
    </header>
  );
});

export default Header;

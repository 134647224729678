// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
exports.push([module.id, "._1wNUlSzUx_9W-EMWiUYk-7{padding:2px 8px;text-decoration:none;font-style:italic;font-weight:700;font-size:12px;line-height:18px}._3oGINKXkbVfdBsG8vnLQSi{color:#64d0e8}._3oGINKXkbVfdBsG8vnLQSi:hover{background:rgba(190,235,252,0.5)}._3oGINKXkbVfdBsG8vnLQSi:active,._3oGINKXkbVfdBsG8vnLQSi:hover:active{background:#2eb6ea;color:#fff}.UhS7sgkM4vq3zQKbzxK6K{color:#333}.UhS7sgkM4vq3zQKbzxK6K:hover{background:#ecedf5}.UhS7sgkM4vq3zQKbzxK6K:active,.UhS7sgkM4vq3zQKbzxK6K:hover:active{background:#131a4f;color:#fff}\n", ""]);
// Exports
exports.locals = {
	"link": "_1wNUlSzUx_9W-EMWiUYk-7",
	"normal": "_3oGINKXkbVfdBsG8vnLQSi",
	"dark": "UhS7sgkM4vq3zQKbzxK6K"
};
module.exports = exports;

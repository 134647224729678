import { Ref, useCallback } from "react";
import useRefEffect from "./useRefEffect";
import useAudio from "../hooks/useAudio";

export default function useHoverAudio<T extends Element>(audioUrl: string | undefined): Ref<T> {
  const { play, pause } = useAudio([audioUrl])[0];
  return useRefEffect(
    useCallback(
      (element: T) => {
        element.addEventListener("mouseenter", () => {
          play();
        });
        element.addEventListener("mouseleave", () => pause());

        return () => {
          element.removeEventListener("mouseenter", () => play());
          element.addEventListener("mouseleave", () => pause());
          pause();
        };
      },
      [play, pause]
    )
  );
}

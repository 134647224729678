// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
exports.push([module.id, "._3IyCFe4eAiicREr5RuyhoZ{color:#b8baca}._3b9yi29dPe3otS9m5HALOb{color:#333}._1HErnkeCMoJvYntTRR4JTZ{color:#fcc067}._2HDJ8WH0WZhW3q8FDUC1w0{color:#fbab00}._3DTETvM2ArOPTP9ETf6BvI{color:#64d0e8}.RRTdByTMF--oDwNAXOFXK{color:#73c243}._3vDjtdDSFMegA0l9fx-WzZ{color:#f44b55}\n", ""]);
// Exports
exports.locals = {
	"default": "_3IyCFe4eAiicREr5RuyhoZ",
	"primary": "_3b9yi29dPe3otS9m5HALOb",
	"secondary": "_1HErnkeCMoJvYntTRR4JTZ",
	"warning": "_2HDJ8WH0WZhW3q8FDUC1w0",
	"info": "_3DTETvM2ArOPTP9ETf6BvI",
	"success": "RRTdByTMF--oDwNAXOFXK",
	"danger": "_3vDjtdDSFMegA0l9fx-WzZ"
};
module.exports = exports;

import { DndProvider, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import React, { PropsWithChildren, useRef } from "react";
import { DragDropManager } from "dnd-core";

const RNDContext = createDndContext(HTML5Backend);

/**
 * This resolve problem with react-dnd: Cannot have two HTML5 backends at the same time
 *
 * See: https://github.com/react-dnd/react-dnd/issues/186#issuecomment-635699417
 */
function DragAndDrop({ children }: PropsWithChildren<{}>): JSX.Element {
  const manager = useRef(RNDContext);
  return <DndProvider manager={manager.current.dragDropManager as DragDropManager}>{children}</DndProvider>;
}

export default DragAndDrop;

import React from "react";
import CoreDropBag from "../../../../ui/DropBag/DropBag";

import styles from "./DropBag.module.scss";

const Button: React.FC<React.ComponentProps<typeof CoreDropBag>> = (props) => {
  return <CoreDropBag {...props} placeholder={props.header} styles={styles} />;
};

export default Button;

import React from "react";
import CoreDragImage from "../../../../ui/DragImage/DragImage";

import styles from "./DragImage.module.scss";

const DragImage: React.FC<React.ComponentProps<typeof CoreDragImage>> = (props) => {
  return <CoreDragImage {...props} styles={styles} />;
};

export default DragImage;

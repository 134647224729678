import React from "react";
import { Modal, Button } from "@lms/ui";
import useHoverAudio from "../../lesson/hooks/useHoverAudio";
import composeRefs from "@seznam/compose-react-refs";
import url from "../assets/sounds/silence.mp3";

interface AudioPermissionModalViewProps {
  visible: boolean;
  onClose: () => void;
}

const AudioPermissionModalView: React.FC<AudioPermissionModalViewProps> = (props) => {
  const hoverAudioRef = useHoverAudio<HTMLDivElement>(url);
  const syntheticAudioRef = React.useRef<HTMLAudioElement>();

  function playConfirmAudio(): void {
    if (syntheticAudioRef.current) {
      syntheticAudioRef.current.dispatchEvent(new Event("mouseenter"));
    }
    props.onClose();
  }

  return (
    <Modal
      theme="info"
      title={"Informacja"}
      message={"Od teraz na stronie pojawią się dźwięki"}
      icon="fas fa-volume-up"
      visible={props.visible}
      onClose={() => props.onClose()}
      persistent={true}
      buttons={[
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <div key={"beware"} ref={composeRefs<any>(syntheticAudioRef, hoverAudioRef)}>
          <Button onClick={() => playConfirmAudio()}>Okej</Button>
        </div>,
      ]}
    />
  );
};

export default AudioPermissionModalView;

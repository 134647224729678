// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
exports.push([module.id, "._2CShMjCziAJv67mND2e1Wl{display:flex;flex-direction:column;justify-content:center;align-items:center;padding:12px;border:1px solid rgba(153,168,198,0.25);box-shadow:0px 2px 4px rgba(153,168,198,0.25);background:white}._2CShMjCziAJv67mND2e1Wl span{font-style:normal;font-weight:600;font-size:12px;line-height:16px;color:#333}._2CShMjCziAJv67mND2e1Wl .RL4uvwtV4GUD1D4ndPbGf{font-weight:700;color:#333}._2CShMjCziAJv67mND2e1Wl ._3a9gOCMmSTVc6JTba_E7KB{color:#8292b1}._2CShMjCziAJv67mND2e1Wl:focus{outline:none}._2PNEtFDg585CfwcAVsBMVz .tippy-svg-arrow p{box-shadow:0px 2px 4px rgba(153,168,198,0.25)}._2PNEtFDg585CfwcAVsBMVz[data-placement^=\"top\"] .tippy-svg-arrow svg{top:11px}._2PNEtFDg585CfwcAVsBMVz[data-placement^=\"left\"] .tippy-svg-arrow svg{left:11px;top:0px}._2PNEtFDg585CfwcAVsBMVz[data-placement^=\"bottom\"] .tippy-svg-arrow svg{top:-12px}._2PNEtFDg585CfwcAVsBMVz[data-placement^=\"right\"] .tippy-svg-arrow svg{right:11px;top:0px}\n", ""]);
// Exports
exports.locals = {
	"popover": "_2CShMjCziAJv67mND2e1Wl",
	"maintxt": "RL4uvwtV4GUD1D4ndPbGf",
	"subtxt": "_3a9gOCMmSTVc6JTba_E7KB",
	"tippy": "_2PNEtFDg585CfwcAVsBMVz"
};
module.exports = exports;

import React from "react";
import { observer } from "mobx-react";

import styles from "./Footer.module.scss";

import fb from "../../assets/images/fb.svg";
import instagram from "../../assets/images/instagram.svg";
import eos from "../../assets/images/eos.svg";
import halostudio from "../../assets/images/halostudio.svg";
import nck from "../../assets/images/nck.svg";

const Footer: React.FC = observer(() => {
  return (
    <footer className={styles.footer}>
      <div className={styles.partners}>
        <a href="https://ladne-halo.pl/" target="_blank" rel="noreferrer">
          <img src={halostudio} alt="Studio Ładne Halo" />
        </a>
        <a href="https://embassyofsound.org/sound" target="_blank" rel="noreferrer">
          <img src={eos} alt="Embassy of sound" />
        </a>
        <a
          href="https://www.nck.pl/en/dotacje-i-stypendia/dotacje/programy-dotacyjne-nck/kultura-w-sieci"
          target="_blank"
          rel="noreferrer"
        >
          <img src={nck} alt="Ministerstwo kultury i dziedzictwa narodowego" />
        </a>
      </div>
      <div className={styles.social}>
        <a href="https://www.facebook.com/Embassy-of-Sound-EoS-108420331064748" target="_blank" rel="noreferrer">
          <img src={fb} alt="Facebook" />
        </a>
        <a href="https://www.instagram.com/embassyofsound_eos/" target="_blank" rel="noreferrer">
          <img src={instagram} alt="Instagram" />
        </a>
      </div>
    </footer>
  );
});

export default Footer;

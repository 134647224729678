import React from "react";
import classnames from "classnames";
import { Modal as CoreModal, Icon } from "../../../../ui";

import styles from "./Modal.module.scss";

const Modal: React.FC<React.ComponentProps<typeof CoreModal>> = (props) => {
  const { title, message, icon, children, ...restProps } = props;

  const content = children ?? (
    <>
      <div className={styles.wrapper}>
        {icon && <Icon className={classnames(icon, styles.icon)} />}
        <div className={styles.right}>
          <h1>{title}</h1>
          {message && <p>{props.message}</p>}
        </div>
      </div>
    </>
  );

  return (
    <CoreModal {...restProps} styles={styles}>
      {content}
    </CoreModal>
  );
};

export default Modal;

import React from "react";
import MathJax from "react-mathjax-preview";

interface MathSvgOptions {
  children: string;
}

const MathSvg: React.FC<MathSvgOptions> = (props: MathSvgOptions) => {
  const dataInline = props.children.replace(/\$\$/g, "$");

  return (
    <MathJax
      math={dataInline}
      script={"https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/MathJax.js"}
      config={{
        SVG: {
          scale: 100,
          minScaleAdjust: 100,
        },
        extensions: ["tex2jax.js"],
        showMathMenu: false,
        jax: ["input/TeX", "output/SVG"],
        tex2jax: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"],
          ],
          processEscapes: true,
        },
      }}
    />
  );
};

export default MathSvg;

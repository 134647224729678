import React from "react";
import { Button as CoreButton } from "../../../../ui";

import styles from "./Button.module.scss";

const Button: React.FC<React.ComponentProps<typeof CoreButton>> = (props) => {
  return <CoreButton {...props} styles={styles} />;
};

export default Button;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
exports.push([module.id, ".N8R2xcr918Zy3phHpCnlQ{background:#333;font-style:normal;font-weight:600;font-size:12px;line-height:16px;color:#fff;border-radius:2px;padding:4px 8px}._2xIu73DiE1RqZ6KC8wqvnn .tippy-svg-arrow{width:10px;height:8px}._2xIu73DiE1RqZ6KC8wqvnn .tippy-svg-arrow path{fill:#333}._2xIu73DiE1RqZ6KC8wqvnn[data-theme~=\"light\"] .N8R2xcr918Zy3phHpCnlQ{background:#ecedf5;color:#333}._2xIu73DiE1RqZ6KC8wqvnn[data-theme~=\"light\"] .tippy-svg-arrow path{fill:#ecedf5}._2xIu73DiE1RqZ6KC8wqvnn[data-placement^=\"top\"] .tippy-svg-arrow svg{top:8px}._2xIu73DiE1RqZ6KC8wqvnn[data-placement^=\"left\"] .tippy-svg-arrow svg{left:9px}._2xIu73DiE1RqZ6KC8wqvnn[data-placement^=\"bottom\"] .tippy-svg-arrow svg{bottom:8px}._2xIu73DiE1RqZ6KC8wqvnn[data-placement^=\"right\"] .tippy-svg-arrow svg{right:9px}\n", ""]);
// Exports
exports.locals = {
	"tooltip": "N8R2xcr918Zy3phHpCnlQ",
	"tippy": "_2xIu73DiE1RqZ6KC8wqvnn"
};
module.exports = exports;

import React, { useEffect, useState } from "react";
import AudioPermissionModalView from "../../../modals/AudioPermissionModalView";

const AudioPermission: React.FC = () => {
  const [audioPermision, setAudioPermision] = useState(false);
  const [isAudioModalActive, setAudioModalActive] = useState(false);

  useEffect(() => {
    if (!window.AudioContext && !audioPermision) {
      setAudioModalActive(true);
    } else {
      setAudioModalActive(false);
    }
  }, [audioPermision]);

  return <AudioPermissionModalView visible={isAudioModalActive} onClose={() => setAudioPermision(true)} />;
};

export default AudioPermission;

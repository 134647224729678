import composeRefs from "@seznam/compose-react-refs";
import classNames from "classnames";
import * as React from "react";
import { useDrag } from "react-dnd";
import { Flipped } from "react-flip-toolkit";

import { Button } from "../";
import useHoverAudio from "../../lesson/hooks/useHoverAudio";
import useHoverAudioFormula from "../../lesson/hooks/useHoverAudioFormula";
import { DRAG_TYPE } from "../DropBag/DropBag";
import { Theme } from "../interfaces/Theme";

import defaultStyles from "./DragLabel.module.scss";

export interface DragLabelValue {
  id: string;
  text: string;
}

export interface DragLabelItem extends DragLabelValue {
  type: typeof DRAG_TYPE;
}

interface DragLabelProps {
  /**
   * Item currently dropped in the box.
   * Undefined means empty box.
   */
  value?: DragLabelValue;
  /**
   * Called when the user clears the box.
   * Set to undefined to disable showing the clear button.
   */
  onClear?: () => void;
  /** Audio file which should play on hover. */
  hoverAudioUrl?: string;
  /** Audio wave formula which should play on hover. */
  hoverAudioFormula?: string;
  theme?: Theme;
  className?: string;
  styles?: Record<string, string>;
  visible?: boolean;
  inBag?: boolean;
}

const DragLabel: React.FC<DragLabelProps> = (props) => {
  const {
    value,
    onClear,
    hoverAudioUrl,
    hoverAudioFormula,
    theme = "default",
    styles = defaultStyles,
    visible,
    inBag,
  } = props;

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: DRAG_TYPE, ...value } as DragLabelItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const hoverAudioRef = useHoverAudio(hoverAudioUrl);
  const hoverAudioFormulaRef = useHoverAudioFormula(hoverAudioFormula);

  const renderLabel = (value: DragLabelValue): React.ReactNode => (
    <div
      className={classNames(styles.label, props.className, styles[theme])}
      style={{ opacity: !visible || isDragging ? 0 : 1 }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={composeRefs<any>(dragRef, hoverAudioRef, hoverAudioFormulaRef)}
    >
      {value.text}
      {onClear != null && (
        <Button
          className={styles.sideLabel}
          color={"alert"}
          shape={"round"}
          size={"small"}
          icon="fas fa-times"
          onClick={onClear}
        />
      )}
    </div>
  );

  const renderValue = (value: DragLabelValue): React.ReactNode => {
    if (!visible) {
      return renderLabel(value);
    }

    return (
      <Flipped flipId={value.id} shouldFlip={(prevData, nextData) => nextData?.lastDroppedId !== value.id}>
        {renderLabel(value)}
      </Flipped>
    );
  };

  return (
    <div className={classNames(styles.slot, styles[theme], props.className, { [styles.slotInvisible]: inBag })}>
      {value != null && renderValue(value)}
    </div>
  );
};
export default DragLabel;

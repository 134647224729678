import { Spinner } from "@lms/ui";
import { hot } from "react-hot-loader/root";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import appConfig from "appConfig";

import NotFoundPage from "../pages/NotFoundPage";

import BasicLayout from "./Layout/BasicLayout";
import usePageTracking from "../hooks/usePageTracking";

import "../assets/styles/index.scss";
import AudioPermission from "./lesson/AudioPermission/AudioPermission";

const CoursePage = React.lazy(() => import("../pages/CoursePage"));
const LessonPage = React.lazy(() => import("../pages/LessonPage"));

const App: React.FC = () => {
  return (
    <Router>
      <Helmet defaultTitle={appConfig.HALO_TITLE} titleTemplate={`%s - ${appConfig.HALO_TITLE}`} />
      <AudioPermission />
      <AppContent />
    </Router>
  );
};

const AppContent: React.FC = () => {
  usePageTracking(appConfig.HALO_GA_ID);

  return (
    <React.Suspense fallback={<Fallback />}>
      <Switch>
        <Route path="/lesson/:lessonId" component={LessonPage} />
        <Route exact path="/" component={CoursePage} />
        <Route component={NotFoundPage} />
      </Switch>
    </React.Suspense>
  );
};

const Fallback = (): JSX.Element => (
  <BasicLayout>
    <Spinner />
  </BasicLayout>
);

export default hot(App);

import { AudioBufferSourceNode, AudioContext } from "standardized-audio-context";
import { useQuery } from "react-query";
import useAudioContext from "./useAudioContext";
import { useEffect, useState } from "react";

interface AudioControls {
  play: (delay?: number) => void;
  pause: () => void;
}
interface AudioInterface extends AudioControls {
  ready: boolean;
  audioContext: AudioContext;
}

export default function useAudio(audioUrls?: Array<string | undefined>): AudioInterface[] {
  const audioContext = useAudioContext();
  const audioBuffers =
    useQuery(
      ["audio", audioUrls],
      async (key: "audio", audioUrls: string[]) =>
        await Promise.all(
          audioUrls.map(async (url) => {
            const response = await fetch(url);
            const data = await response.arrayBuffer();
            return audioContext.decodeAudioData(data);
          })
        ),
      { enabled: audioUrls != null }
    ).data ?? [];

  function generateInterface(bufferIndex: number): AudioControls {
    let audioSource: AudioBufferSourceNode<AudioContext>;

    function pause(): void {
      audioSource?.stop();
    }

    function play(delay?: number): void {
      pause();
      if (!audioBuffers[bufferIndex]) return;
      audioSource = audioContext.createBufferSource();
      audioSource.connect(audioContext.destination);
      audioSource.buffer = audioBuffers[bufferIndex];
      audioSource.start(delay ?? 0);
    }

    return { play, pause };
  }

  function generateInterfaces(): AudioInterface[] {
    const ready = !!audioBuffers.length;
    return audioUrls ? audioUrls.map((u, i) => ({ ...generateInterface(i), ready, audioContext })) : [];
  }

  const [interfaces, setInterfaces] = useState<AudioInterface[]>(generateInterfaces());

  useEffect(() => {
    if (audioBuffers.length) {
      setInterfaces(generateInterfaces());
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioBuffers]);

  return interfaces;
}

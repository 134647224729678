import classNames from "classnames";
import React from "react";
import { Theme } from "../interfaces/Theme";
import styles from "./Button.module.scss";

type Shape = "round" | "ghost" | "normal";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLElement> {
  component?: React.ElementType;
  href?: string; // For link buttons.
  icon?: string;
  size?: "standard" | "small";
  shape?: Shape;
  theme?: Theme;
  iconPlacement?: "left" | "right";
  styles?: Record<string, string>;
  active?: boolean;
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    component: Component = "button",
    icon,
    size = "standard",
    shape = "ghost",
    theme = "default",
    iconPlacement = "left",
    active = false,
    styles: s = styles,
    children,
    ...buttonProps
  } = props;

  function renderIcon(icon: string): React.ReactNode {
    const iconFamily = icon.match(/^(fas)|(mdi)|(cil)|(cid)|(cis)|(cif)|(cib)/g)?.[0];

    if (!iconFamily) {
      return <label>&#xfffd;</label>;
    }

    return <i className={icon} />;
  }

  return (
    <Component
      {...buttonProps}
      className={classNames(s.button, buttonProps.className, s[size], s[shape], s[theme], {
        [s.disabled]: buttonProps.disabled,
        [s.soloIcon]: children == null && icon != null,
        [s.rightIcon]: iconPlacement === "right",
        [s.active]: active,
      })}
    >
      {icon != null && iconPlacement === "left" && renderIcon(icon)}
      {children != null && <span>{children}</span>}
      {icon != null && iconPlacement === "right" && renderIcon(icon)}
    </Component>
  );
};

export default Button;

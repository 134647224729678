import React from "react";
import classnames from "classnames";
import { Theme } from "../interfaces/Theme";

import defaultStyles from "./Icon.module.scss";

interface IconProps {
  className: string;
  theme?: Theme;
  onClick?: () => void;
  styles?: Record<string, string>;
}

const Icon: React.FC<IconProps> = (props: IconProps) => {
  const { theme = "default", styles = defaultStyles } = props;

  return <i className={classnames(props.className, styles[theme])} onClick={props.onClick} />;
};

export default Icon;

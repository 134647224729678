import React from "react";

import { Button, Modal } from "@lms/ui";
import { Theme } from "../../interfaces/Theme";

export interface AlertProps {
  title: string;
  message: string;
  visible: boolean;
  icon?: string;
  onClose?: () => void;
  onDismiss?: () => void;
  persistent?: boolean;
  theme?: Theme;
  confirmButtonText?: string;
}

const Alert: React.FC<AlertProps> = (props: AlertProps) => {
  const { confirmButtonText = "OK" } = props;

  function onDismiss(): void {
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  const buttons = [
    <Button key="1" color={props.theme} onClick={() => onDismiss()}>
      {confirmButtonText}
    </Button>,
  ];

  return <Modal {...props} buttons={buttons} />;
};

export default Alert;
